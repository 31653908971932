// Core
import { ElementType } from "react";
import cx from "classnames";

// Components
import { Menu as AntMenu, MenuProps } from "antd";
import { Link, type LinkProps } from "../Link";
import { Icon } from "../Icon";

// Utils
import st from "./styles.module.css";

const MenuItemLink = (linkProps: {
  children: LinkProps["children"];
  link?: LinkProps & { component?: ElementType };
}) => {
  const { link, children } = linkProps;
  if (!link) {
    return <>{children}</>;
  }
  const { component: ItemLink = Link, href, ...otherLinkProps } = link;
  return (
    <ItemLink href={String(href)} {...otherLinkProps}>
      {children}
    </ItemLink>
  );
};

export type MenuItemType = {
  id?: number;
  key?: string;
  link?: LinkProps & {
    component?: ElementType;
  };
  icon?: string;
  text?: string;
  isSubMenu?: boolean;
};
export const MenuItem = (props: MenuItemType) => {
  const { isSubMenu, icon, text, link } = props;
  const contentJSX = (
    <>
      {!!icon && <Icon name={icon} size="18" />}
      {!!text && text}
    </>
  );
  if (isSubMenu) {
    return <>{text}</>;
  }
  return (
    <MenuItemLink link={link}>
      <>{contentJSX}</>
    </MenuItemLink>
  );
};

export type MenuPropsType = MenuProps;

export const Menu = (props: MenuPropsType) => {
  return (
    <AntMenu
      className={cx(st.menu)}
      expandIcon={<Icon name="DownOutlined" />}
      selectable={false}
      disabledOverflow
      {...props}
    />
  );
};
