"use client";

// Components
import { LayoutAppHeader3Cols } from "@/client/components/layouts/layout-app-header-3cols";
import { NavbarProfile } from "@/client/domains/user/navigation/components/navbar-profile";
import { SearchBar } from "@/client/domains/search/search-bar";
import { Flex } from "@/client/components/ui/Flex";
import { HeaderNavbar } from "@/client/components/page-elements/header-navbar";

// Utils
import { verifyFeatures } from "@/common/utils/verify-features";

export const HeaderShop = () => {
  const navbarJSX = verifyFeatures().isOrdersList ? (
    <Flex justify="center" align="center">
      <HeaderNavbar />
    </Flex>
  ) : null;

  return (
    <LayoutAppHeader3Cols userMenu={<NavbarProfile />} main={<SearchBar />} menu={navbarJSX} />
  );
};
