// Definitions
import { TFunction } from "i18next";
import type { MenuPropsType } from "@/client/components/ui/Menu";
import type { HeaderNavbarItemType } from "@/client/components/page-elements/header-navbar/header-navbar.types";

// Component
import { MenuItem } from "@/client/components/ui/Menu";
import { Link, type LinkProps } from "@/client/components/common/link";

// Utils
import { routes } from "@/client/definitions/book";

export type HeaderNavigationModelProps = {
  items: HeaderNavbarItemType[];
  t: TFunction;
};

export const headerNavbarItems = (): HeaderNavbarItemType[] => {
  return [
    {
      id: 1,
      text: "",
      link: routes.dashboard,
      icon: "HomeFilled",
    },
    {
      id: 2,
      text: "common:header.navbar.orders",
      link: routes.orders,
    },
    /*
    * Example: multilevel menu
    {
      id: 3,
      text: "common:navigation.main.orders",
      link: "orders",
      children: [
        {
          id: 3,
          text: "common:navigation.main.orders",
          link: `${routes.orders}?sort[id]=desc`,
        },
      ],
    },
    */
  ];
};

export const headerNavbarRenderModel = (
  props: HeaderNavigationModelProps,
): MenuPropsType["items"] => {
  const { t, items } = props;

  return items.map((item) => {
    const textValue = item.text ? t(item.text) : "";
    const CellLink = (linkProps: LinkProps) => Link({ ...linkProps, noStyles: true });

    return {
      key: item.id,
      label: MenuItem({
        link: {
          href: item.link,
          component: CellLink,
        },
        text: textValue,
        icon: item.icon,
        isSubMenu: !!item?.children,
      }),
      children: item.children ? headerNavbarRenderModel({ t, items: item.children }) : void 0,
    };
  });
};
