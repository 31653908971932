import { useTranslation } from "react-i18next";

// Components
import { Menu } from "@/client/components/ui/Menu";

// Utils
import st from "./styles.module.css";
import {
  headerNavbarItems,
  headerNavbarRenderModel,
} from "@/client/components/page-elements/header-navbar/render-model";

export const HeaderNavbar = () => {
  const { t } = useTranslation();

  const menuItems = headerNavbarRenderModel({
    items: headerNavbarItems(),
    t,
  });

  return (
    <nav className={st.navbar} data-testid="header-navbar">
      <Menu items={menuItems} mode="horizontal" theme="dark" />
    </nav>
  );
};
